import React from "react"

import DashHeader from 'components/common/DashHeader'
import DashSidebar from 'components/common/Sidebar'
import BodyHeader from "components/common/BodyHeader"

export default function DashHome() {

	return (
<div className="admin-page">

    <DashSidebar current="home" />

    <DashHeader paths={[
        {
            name: "Tableau de bord",
            url: "/dashboard"
        }
    ]} />

    <div className="admin-body">

        <BodyHeader 
            id="dashboard" 
            title="Bienvenue sur votre espace personnel !" 
        >
            <div>
            Vous êtes actuellement connecté avec un <strong>compte utilisateur</strong> (cf
            l'adresse email en haut à droite), et vous avez accès à des <strong>membres associés</strong> à votre 
            compte :<br/>
            ➡️ <em>si vous êtes parent :</em> fiche de votre ou vos enfant(s) <br/>
            ➡️ <em>si vous être un chef ou un membre administratif :</em> votre propre fiche + celle des membres de vos compagnies avec informations limité selon votre rôle<br/>
            <br/>
            À gauche, vous pouvez voir différents onglets :
            <ul style={{marginTop:5, marginBottom:5}}>
                <li>
                    <strong>Mes Données :</strong>&nbsp;
                    pour accéder aux fiches membres personnelles associées à ce compte utilisateur 
                </li>
                <li>
                    <strong>S'inscrire :</strong>&nbsp;
                    pour vous inscrire aux différents évènements proposés par la CSSB (camp d'été, inscription à l'année, ...)
                </li>
                <li>
                    <strong>Calendrier :</strong>&nbsp;
                    planning des activités de votre compagnie telles que rentrées par le commandant de compagnie dans son Agenda Google
                </li>
                <li>
                    <strong>Besoin d'aide :</strong>&nbsp;
                    un formulaire de contact en cas de problème, requête, suggestion, ...
                </li>
            </ul>
            Si vous êtes <strong>chef</strong> ou <strong>membre administratif</strong> (voir profile indiqué en haut à droite), vous avez à gauche :
            <ul style={{marginTop:5, marginBottom:5}}>
                <li>
                    <strong>Listing :</strong>&nbsp;
                    la liste des membres auxquels vous avez accès, pour consulter leur fiches personnelles,
                    éventuellement valider leur documents, etc ... 
                </li>
                <li>
                    <strong>Évènements :</strong>&nbsp;
                    liste de l'ensemble des évènements CSSB, et aperçu parmi la liste des membres auxquels vous avez accès
                    des inscriptions, réceptions de paiement, etc ...
                </li>
            </ul>
            <em>
                Le site est (encore) un peu rustique, car toujours en cours de développement. Donc 
                n'hésitez pas à utiliser le formulaire de contact si vous voyez le moindre problème, 
                ou d'éventuelles pistes d'amélioration ...
            </em> 😉
            </div>
        </BodyHeader>

        <div className="admin-section">

            <strong>Informations année 2024/2025 (nouveau système)</strong>
            <ul>
                <li> afin de simplifier l'administratif et vos démarches, les inscriptions se font exclusivement sur Central</li>
                <li> pour ceux <strong>ayant participé au camp ou inscrit en 2023/2024</strong> : 
                    <ol>
                        <li>vérifier que les données rentrées pour le camp sont bien validées, mettez les éventuellement à jour dans l'onglet <strong>Mes Données</strong></li>
                        <li>inscrivez vous dans l'évènement "Inscription 2024/2025 - [...]" de votre compagnie (+ paiement ensuite) dans l'onglet <strong>S'inscrire</strong></li>
                    </ol>
                </li>
                <li> pour les <strong>nouveaux arrivants</strong> : 
                    <ol>
                        <li>rentrez tout les informations/fichiers demandés dans <strong>Mes Données</strong>, votre secrétaire les validera ensuite ...</li>
                        <li>une fois votre fiche validée par votre secrétaire, allez dans l'onglet <strong>S'inscrire</strong> pour 
                        vous inscrire dans votre compagnie de rattachement (+ paiement ensuite)</li>
                    </ol>
                </li>
            </ul>
            <em>Ces informations seront éventuellement mises à jour au fur et à mesure, n'hésitez pas à revenir régulièrement sur cette page ...</em>

            <BodyHeader title="Dernière mise à jour : version 1.0 (11 novembre 2024)" id="version">
                <div>
                    Suite à la session de travail du service informatique lors du WK CSAO à Saintes (1-3 Novembre 2024), 
                    de nouvelles améliorations / ajouts ont été apportés à Central,
                    avec comme changements majeurs :
                    <ul style={{marginTop: 5, marginBottom: 5}}>
                        <li>re-travail du module Évènements, avec possibilité de télécharger des fiches administratives / trombinoscopes / fiches sanitaires de liaison</li>
                        <li>ajout des calendriers des compagnies, directement reliés aux Agenda Google des commandants de compagnies</li>
                    </ul>
                    Plus de détails à propos des changements dans l'onglet <a href={`${window.clientURL}/dashboard/changelog#v1.0`}><strong>Mises à jour</strong></a>.
                    Auteurs : CM Arnaud CHARTIER-KASLTER (1ère Cie) et CEM Thibaut LUNET (5ème Cie)
                </div>
            </BodyHeader>
            

        </div>

    </div>

    

</div>
	)
}